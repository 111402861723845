@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #2f49d1;
  --main-hover: #3955e9;
  --input-bg: #f2f2f2;
  --input-radius: 7px;
  --label-color: #828282;
  --table-border: #84bdf2;
}
.cirle {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(153, 186, 221);
  border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.leaflet-container {
  width: 100%;
  height: 400px;
}
body {
  -webkit-print-color-adjust: exact;
}

.ant-input-textarea {
  height: 160px !important;
}
.ant-input-textarea .ant-input {
  height: 160px !important;
}

.mapModal{
  margin: 0 auto !important;
  /*height: 100% !important;*/
  width: 85% !important;
}
.ant-modal-content{
  margin: 0 !important;
  width: 100% !important;
}

