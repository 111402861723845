@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #2f49d1;
  --main-hover: #3955e9;
  --input-bg: #f2f2f2;
  --input-radius: 7px;
  --label-color: #828282;
  --table-border: #84bdf2;
}
.cirle {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(153, 186, 221);
  border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.leaflet-container {
  width: 100%;
  height: 400px;
}
body {
  -webkit-print-color-adjust: exact;
}

.ant-input-textarea {
  height: 160px !important;
}
.ant-input-textarea .ant-input {
  height: 160px !important;
}

.mapModal{
  margin: 0 auto !important;
  /*height: 100% !important;*/
  width: 85% !important;
}
.ant-modal-content{
  margin: 0 !important;
  width: 100% !important;
}


.sidebar_header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.ant-layout-sider {
  background-color: var(--main-color) !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--main-color) !important;
}
.ant-layout-sider-trigger {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  background-color: var(--main-color) !important;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: white !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--main-hover) !important;
  border-radius: 10px;
}
.ant-menu-inline {
  width: 90% !important;
  margin: 0 auto !important;
}
.site-layout-background {
  background-color: white;
  border-radius: 12px;
}

/* Swiper */

.cancel_link {
  color: red;
  position: absolute;
  top: 0px;
  right: -4px;
  cursor: pointer;
  font-size: 16px;
}

.active_link {
  border-bottom: 2px solid var(--main-color);
}

.link {
  padding: 5px 10px;
  position: relative;
}

.link_bar {
  display: flex;
  grid-gap: 10px;
  height: 35px;
  overflow: auto;
  white-space: nowrap;
}

.link_bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.link_bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-menu-item {
  padding-left: 25px !important;
}

/* qoshimcha */
.ant-menu-root {
  overflow: auto !important;
  height: calc(100vh - 140px) !important;
}

.ant-layout-sider-children {
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


  .ant-input-affix-wrapper {
    border: none;
    border-radius: 10px;
    height: 35px;
    padding: 0 15px;
    margin-left: 0.75rem;
    background-color: #fff !important;
  }
  
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px dashed #ccc;
  display: flex;
  color: #999;
  align-items: center;
  border-radius: var(--input-radius);
  background-color: var(--input-bg);
  grid-gap: 10px;
  padding: 3px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.form_input .ant-input {
    border-radius: var(--input-radius) !important;
    background-color: var(--input-bg) !important; 
}
.ant-input-number{
    border-radius: var(--input-radius) !important;
    background-color: var(--input-bg) !important;
    width: 100%;
    border: none !important;
}

.ant-select .ant-select-selector {
    display: block !important;
    
    background-color: var(--input-bg) !important;
    border: none !important;
    border-radius: var(--input-radius) !important;
  }

  .ant-select {
    width: 100% !important;
  }

   .react-tel-input input{
       outline-color: #40a9ff !important;
       outline-width: 1px !important;
   }

   .label {
       color: var(--label-color);
   }
   .ant-modal-header {
       padding: 12px 24px !important;
   }
   .grid_form {
    display: grid;
    grid-gap: 25px;
    margin-top: 10px;
}
.qoldiq_red {
    border: 1px solid #FF7875 !important;
  }
table {
  border-collapse: collapse !important;
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 10px;
}
tr {
  cursor: pointer;
  border-top: 1px solid var(--table-border) !important;
  border-bottom: 1px solid var(--table-border) !important;
}
td,
th {
  border-right: 1px solid var(--table-border) !important;
  border-bottom: 1px solid var(--table-border) !important;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 0px !important;
}
/* tr:last-child,
  td:last-child,
  th:last-child {
    border: none !important;
  } */
tr:nth-child(even) {
  background-color: #f3f6f9;
}
tr:nth-child(odd) {
  background-color: white;
}
thead tr:first-child {
  background-color: var(--main-color) !important;
  color: white;
  border: none !important;
}
.ant-table-summary .ant-table-cell,
.ant-table-summary .ant-table-cell span {
  background-color: var(--main-color);
  color: white;
}
.ant-table-thead {
  background-color: var(--main-color) !important;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.Table_div {
  overflow: auto;
  background-color: white;
  margin: 15px auto;
}
.ant-table.ant-table-small .ant-table-thead > tr > th:first-child,
.ant-table.ant-table-small .ant-table-tbody > tr > td:first-child {
  padding: 0 !important;
}
.ant-table.ant-table-small .ant-table-thead > tr > th {
  background-color: var(--main-color) !important;
  -webkit-print-color-adjust: exact;
  border-bottom: 1px solid var(--table-border) !important;
}
/* .ant-table.ant-table-small .ant-table-tbody > tr > td { */
/* padding-left: 1rem !important; */
/* } */
.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}
.table_head {
  background-color: var(--main-color);
  color: white;
  padding: 2px 2px;
  text-align: center;
}

.main_table {
  margin-top: 10px;
  width: 100%;
}

.table_img {
  height: 20px;
}

.clickRowStyl {
  background-color: #add8e6 !important;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #add8e6 !important;
}
.ant-table-cell {
  border-bottom: none !important;
  padding: 2px 5px !important;
}
.ant-table-content {
  border-radius: var(--input-radius) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.ant-table-tbody tr:first-child {
  border-top: none !important;
}
.ant-table-summary .ant-table-cell {
  text-align: right;
  /* display: flex !important;
    justify-content: flex-end !important; */
}

.form_container {
    padding-bottom: 30px;
}
.form-btn {
    display: flex;
    align-items: center;
    border-radius: var(--input-radius);
    padding: 15px 30px;
}
.btn-back {
    background-color: var(--input-bg) !important;
    color: var(--main-color);
}
.btn-submit {
    background-color: var(--main-color) !important;
    color: white;
}
.modal-title {
    color: var(--main-color);
}
.ant-modal-header {
    background-color: var(--input-bg);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.ant-modal-content {
    border-radius: 12px;
}
.ant-modal-close-x {
    font-size: 20px !important;
    color: #FF4B4B !important;
}
.ant-modal-body {
    padding: 15px 20px !important;
}
.form_input {
    min-width: 115px;
}
form {
    overflow: auto;
}
.currency_input {
    width: 100%;
    border-radius: var(--input-radius);
    border: 1px solid #d9d9d9;
    background-color: var(--input-bg);
    padding: 4px 11px;
}
.currency_input:focus {
    outline: none;
}
.action_btn {
    color: #fff !important;
    display: flex !important;
    justify-content: center;
    padding: 10px !important;
    align-items: center;
    border: none !important;
    border-radius: var(--input-radius) !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.main-btn {
    background-color: var(--main-color) !important;
}
.edit-btn {
    background-color: orange !important;
}
.delete-btn {
    background-color: #DC200E !important;
}
.action_icon {
    font-size: 20px;
}

@media only screen and (max-width: 500px) {
    .user_avatar {
        display: none !important;
    }
    .action_text {
        display: none !important;
    }
    .site-layout-background {
        padding: 10px !important;
    }
}
.service_item {
    color: white;
    display: flex;
    background-color: var(--main-color);    
    border-radius: 10px;
    padding: 10px 20px;
    align-items: center;
    grid-gap: 15px;
    cursor: pointer;
}
.service_grid {
    display: grid;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 10%;
}
.login_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_form {
  width: 350px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
}
.login_wrapper .ant-input,
.login_wrapper .ant-input-password {
  border-radius: var(--input-radius) !important;
  background-color: var(--input-bg) !important;
  border: none !important;
  margin: 0 !important;
  height: 30px !important;
}
.login_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

