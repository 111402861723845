.sidebar_header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.ant-layout-sider {
  background-color: var(--main-color) !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--main-color) !important;
}
.ant-layout-sider-trigger {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  background-color: var(--main-color) !important;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: white !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--main-hover) !important;
  border-radius: 10px;
}
.ant-menu-inline {
  width: 90% !important;
  margin: 0 auto !important;
}
.site-layout-background {
  background-color: white;
  border-radius: 12px;
}

/* Swiper */

.cancel_link {
  color: red;
  position: absolute;
  top: 0px;
  right: -4px;
  cursor: pointer;
  font-size: 16px;
}

.active_link {
  border-bottom: 2px solid var(--main-color);
}

.link {
  padding: 5px 10px;
  position: relative;
}

.link_bar {
  display: flex;
  grid-gap: 10px;
  height: 35px;
  overflow: auto;
  white-space: nowrap;
}

.link_bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.link_bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-menu-item {
  padding-left: 25px !important;
}

/* qoshimcha */
.ant-menu-root {
  overflow: auto !important;
  height: calc(100vh - 140px) !important;
}

.ant-layout-sider-children {
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  position: sticky;
  top: 0;
}
