input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px dashed #ccc;
  display: flex;
  color: #999;
  align-items: center;
  border-radius: var(--input-radius);
  background-color: var(--input-bg);
  grid-gap: 10px;
  padding: 3px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
